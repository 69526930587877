export const types = {
  UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
  SET_COMPANIES: "SET_COMPANIES",
  REQUEST_PANEL_COMPANIES: "REQUEST_PANEL_COMPANIES",
  SUCCESS_PANEL_COMPANIES: "SUCCESS_PANEL_COMPANIES",
  FAILURE_PANEL_COMPANIES: "FAILURE_PANEL_COMPANIES",
  PANEL_STOPPED: "PANEL_STOPPED",
  UPDATE_COMPANY: "UPDATE_COMPANY",
  SET_SELECTED_COMPANY: "SET_SELECTED_COMPANY",
  SUPERVISION_CHANGE_MAIN_SENSOR: "SUPERVISION_CHANGE_MAIN_SENSOR",
  SET_SCROLL_TO_SIGNAL: "SET_SCROLL_TO_SIGNAL",
  SUPERVISION_CHANGE_DEVICE_ID: "SUPERVISION_CHANGE_DEVICE_ID",

  SET_SHOW_IN_UTC: "SET_SHOW_IN_UTC",

  UPDATE_NUMBER_OF_WIDGET_LOADED: "UPDATE_NUMBER_OF_WIDGET_LOADED",
  SET_SERVER_ERROR: "SET_SERVER_ERROR",
  SET_BACKTO_SUPERPOWER: "SET_BACKTO_SUPERPOWER",
  SET_CLOSE_ANIMATION_SUPERPOWER: "SET_CLOSE_ANIMATION_SUPERPOWER",
  SET_SIGNAL_OFFSET: "SET_SIGNAL_OFFSET",
  SUPERVISION_CHANGE_STATUS_CODE: "SUPERVISION_CHANGE_STATUS_CODE",

  CATENARY_POINTS_SET_OFFSET_EVENTS: "CATENARY_POINTS_SET_OFFSET_EVENTS",
  CATENARY_POINTS_SET_OFFSET_POINTS: "CATENARY_POINTS_SET_OFFSET_POINTS",
  CATENARY_POINTS_SCROLL_TO_EVENT: "CATENARY_POINTS_SCROLL_TO_EVENT",
  CATENARY_POINTS_SCROLL_TO_POINT: "CATENARY_POINTS_SCROLL_TO_POINT",
  DISPLAYED_DRAGGABLE_NOTIC: "DISPLAYED_DRAGGABLE_NOTIC",
  LAST_URL_HISTORY: "LAST_URL_HISTORY",

  GET_USER_ACTIVITY_REQUEST: "GET_USER_ACTIVITY_REQUEST",
  GET_USER_ACTIVITY_SUCCESS: "GET_USER_ACTIVITY_SUCCESS",
  GET_USER_ACTIVITY_FAILURE: "GET_USER_ACTIVITY_FAILURE",

  // Panel
  PANEL_LOADING: "PANEL_LOADING",
  PANEL_REDIRECT: "PANEL_REDIRECT",
  ACCOUNT_AUTHENTICATED: "ACCOUNT_AUTHENTICATED",

  // Notifications
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  MARK_ALL_AS_READ: "MARK_ALL_AS_READ",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
  UPDATE_NEW_NOTIFICATION: "UPDATE_NEW_NOTIFICATION",
  OPEN_NOTIFICATION: "OPEN_NOTIFICATION",
  CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",

  SET_POINTDETAIL: "SET_POINTDETAIL",

  // Widgets
  GET_WIDGET_REQUEST: "GET_WIDGET_REQUEST",
  GET_WIDGET_TOP_LEFT_REQUEST: "GET_WIDGET_TOP_LEFT_REQUEST",
  GET_WIDGET_TOP_RIGHT_REQUEST: "GET_WIDGET_TOP_RIGHT_REQUEST",
  GET_WIDGET_BOTTOM_LEFT_REQUEST: "GET_WIDGET_BOTTOM_LEFT_REQUEST",
  GET_WIDGET_BOTTOM_RIGHT_REQUEST: "GET_WIDGET_BOTTOM_RIGHT_REQUEST",
  GET_WIDGETS_SUCCESS: "GET_WIDGETS_SUCCESS",
  GET_WIDGET_SUCCESS: "GET_WIDGET_SUCCESS",
  GET_WIDGET_FAILURE: "GET_WIDGET_FAILURE",
  UPDATE_WIDGET: "UPDATE_WIDGET",
  CREATE_WIDGET: "CREATE_WIDGET",
  DELETE_WIDGET: "DELETE_WIDGET",
  GET_NEW_EVENTS_REQUEST: "GET_NEW_EVENTS_REQUEST",
  GET_NEW_EVENTS_SUCCESS: "GET_NEW_EVENTS_SUCCESS",
  GET_POINT_STATUS_REQUEST: "GET_POINT_STATUS_REQUEST",
  GET_POINT_STATUS_SUCCESS: "GET_POINT_STATUS_SUCCESS",
  UPDATE_WIDGET_SUCCESS: "UPDATE_WIDGET_SUCCESS",
  GET_SERVER_LOG_REQUEST: "GET_SERVER_LOG_REQUEST",
  GET_SERVER_LOG_SUCCESS: "GET_SERVER_LOG_SUCCESS",

  // Calendar
  UPDATE_CALENDAR_HIGHLIGHT: "UPDATE_CALENDAR_HIGHLIGHT",
  UPDATE_CALENDAR_TOOLS_OPEN: "UPDATE_CALENDAR_TOOLS_OPEN",
  CHANGE_CALENDAR_SIGNALS: "CHANGE_CALENDAR_SIGNALS",
  UPDATE_CALENDAR_SIGNALS: "UPDATE_CALENDAR_SIGNALS",
  UPDATE_CALENDAR_SIGNALS_IDS: "UPDATE_CALENDAR_SIGNALS_IDS",
  GET_CALENDAR_SIGNALS_REQUEST: "GET_CALENDAR_SIGNALS_REQUEST",
  UPDATE_CALENDAR_MAP_OPTIONS: "UPDATE_CALENDAR_MAP_OPTIONS",
  UPDATE_IS_RELOAD_QUERY_CALENDAR_TRIPS:
    "UPDATE_IS_RELOAD_QUERY_CALENDAR_TRIPS",

  UPDATE_SELECTED_TRIP: "UPDATE_SELECTED_TRIP",
  UPDATE_TRIP_INFO: "UPDATE_TRIP_INFO",

  GET_TRIGGERS_REQUEST: "GET_TRIGGERS_REQUEST",
  GET_TRIGGERS_SUCCESS: "GET_TRIGGERS_SUCCESS",
  GET_TRIGGERS_FAILURE: "GET_TRIGGERS_FAILURE",

  CHANGE_SELECTED_TAB: "CHANGE_SELECTED_TAB",
  CHANGE_SELECTED_FILTER: "CHANGE_SELECTED_FILTER",
  CHANGE_LAST_EVENTS_COUNTS: "CHANGE_LAST_EVENTS_COUNTS",
  CHANGE_EVENT_PERIOD_FROM: "CHANGE_EVENT_PERIOD_FROM",
  CHANGE_EVENT_PERIOD_TO: "CHANGE_EVENT_PERIOD_TO",

  VIDEO_LOADED: "VIDEO_LOADED",

  SET_BASIC_INFO: "SET_BASIC_INFO",
  SET_CURRENT_TIME: "SET_CURRENT_TIME",

  UPDATE_FIGURE: "UPDATE_FIGURE",
  DELETE_FIGURE: "DELETE_FIGURE",
  GET_FIGURE_FAILURE: "GET_FIGURE_FAILURE",
  GET_FIGURE_REQUEST: "GET_FIGURE_REQUEST",
  GET_FIGURE_SUCCESS: "GET_FIGURE_SUCCESS",
  GET_ADD_FIGURE_REQUEST: "GET_ADD_FIGURE_REQUEST",
  GET_ADD_FIGURE_SUCCESS: "GET_ADD_FIGURE_SUCCESS",
  GET_ADD_FIGURE_FAILURE: "GET_ADD_FIGURE_FAILURE",
  GET_SIGNAL_DATA_FAILURE: "GET_SIGNAL_DATA_FAILURE",
  GET_SIGNAL_DATA_REQUEST: "GET_SIGNAL_DATA_REQUEST",
  GET_SIGNAL_DATA_SUCCESS: "GET_SIGNAL_DATA_SUCCESS",
  GET_VIDEO_FAILURE: "GET_VIDEO_FAILURE",
  GET_VIDEO_REQUEST: "GET_VIDEO_REQUEST",
  GET_VIDEO_SUCCESS: "GET_VIDEO_SUCCESS",
  GET_SIGNAL_VIDEO_PROGRESS: "GET_SIGNAL_VIDEO_PROGRESS",
  UPDATE_FIGURE_PROPERTIES: "UPDATE_FIGURE_PROPERTIES",
  UPDATE_POSITION: "UPDATE_POSITION",
  UPDATE_SIGNAL_DURATION: "UPDATE_SIGNAL_DURATION",
  UPDATE_ONE_FIGURE: "UPDATE_ONE_FIGURE",

  DOWNLOAD_SIGNAL_START: "DOWNLOAD_SIGNAL_START",
  DOWNLOAD_SIGNAL_END: "DOWNLOAD_SIGNAL_END",
  UPDATE_VIDEO_DURATION: "UPDATE_VIDEO_DURATION",

  GET_CHOOSE_TRIP_FAILURE: "GET_CHOOSE_TRIP_FAILURE",
  GET_CHOOSE_TRIP_REQUEST: "GET_CHOOSE_TRIP_REQUEST",
  GET_CHOOSE_TRIP_SUCCESS: "GET_CHOOSE_TRIP_SUCCESS",
  SET_CHOOSE_TRIP_LOADING: "SET_CHOOSE_TRIP_LOADING",
  SET_CHOOSE_TRIP_NOTIFICATIONS: "SET_CHOOSE_TRIP_NOTIFICATIONS",
  SET_CHOOSE_TRIP_SELECTED_DEVICE_BOX: "SET_CHOOSE_TRIP_SELECTED_DEVICE_BOX",
  SET_TRIPS_DATE: "SET_TRIPS_DATE",

  GET_EVENTS_FAILURE: "GET_EVENTS_FAILURE",
  GET_EVENTS_REQUEST: "GET_EVENTS_REQUEST",
  UPDATE_CAME_BACK_FROM_POINT_DETAIL: "UPDATE_CAME_BACK_FROM_POINT_DETAIL",

  GET_EVENTS_SUCCESS: "GET_EVENTS_SUCCESS",
  UPDATE_HIGHLIGHTED_EVENT: "UPDATE_HIGHLIGHTED_EVENT",
  UPDATE_HIGHLIGHTED_POINT: "UPDATE_HIGHLIGHTED_POINT",
  UPDATE_SELECTED_EVENTS: "UPDATE_SELECTED_EVENTS",
  UPDATE_SELECTED_POINTS: "UPDATE_SELECTED_POINTS",
  UPDATE_TOOLS_OPEN: "UPDATE_TOOLS_OPEN",
  SET_SELECTED_SECTION: "SET_SELECTED_SECTION",
  UPDATE_EVENTS_SORT_BY: "UPDATE_EVENTS_SORT_BY",
  UPDATE_POINTS_SORT_BY: "UPDATE_POINTS_SORT_BY",
  UPDATE_POINTS_SORT_BY_STATUS: "UPDATE_POINTS_SORT_BY_STATUS",
  UPDATE_POINTS_SORT_BY_TYPE: "UPDATE_POINTS_SORT_BY_TYPE",
  UPDATE_POINTS_SORT_BY_Health: "UPDATE_POINTS_SORT_BY_Health",
  UPDATE_POINTS_SORT_BY_ABNORMALITY_TYPE:
    "UPDATE_POINTS_SORT_BY_ABNORMALITY_TYPE",
  UPDATE_POINTS_FILTER_BY: "UPDATE_POINTS_FILTER_BY",
  UPDATE_IS_OPEN_FILTER: "UPDATE_IS_OPEN_FILTER",
  UPDATE_POINT_FILTER: "UPDATE_POINT_FILTER",
  UPDATE_CHECK_STATUS_FILTER: "UPDATE_CHECK_STATUS_FILTER",
  UPDATE_IGNORED_STATUS_FILTER: "UPDATE_IGNORED_STATUS_FILTER",
  UPDATE_EVENTS_TYPE: "UPDATE_EVENTS_TYPE",
  UPDATE_EVENTS_TYPE_PANTOGRAPH: "UPDATE_EVENTS_TYPE_PANTOGRAPH",
  UPDATE_IS_IMPORTED_DATA: "UPDATE_IS_IMPORTED_DATA",
  UPDATE_IS_RELOAD_QUERY_CATENARY_POINT:
    "UPDATE_IS_RELOAD_QUERY_CATENARY_POINT",

  GET_POINTS_SUCCESS: "GET_POINTS_SUCCESS",
  GET_POINTS_FAILURE: "GET_POINTS_FAILURE",
  GET_POINTS_REQUEST: "GET_POINTS_REQUEST",

  UPDATE_EVENTS_FILTER_COUNTS: "UPDATE_EVENTS_FILTER_COUNTS",
  UPDATE_EVENTS_FILTER_RANGE: "UPDATE_EVENTS_FILTER_RANGE",
  UPDATE_POINTS_FILTER_DATE: "UPDATE_POINTS_FILTER_DATE",
  UPDATE_POINTS_FILTER_RANGE: "UPDATE_POINTS_FILTER_RANGE",
  UPDATE_SELECTED_EVENTS_FILTER: "UPDATE_SELECTED_EVENTS_FILTER",
  UPDATE_SELECTED_POINTS_FILTER: "UPDATE_SELECTED_POINTS_FILTER",
  UPDATE_SELECTED_TAB: "UPDATE_SELECTED_TAB",
  UPDATE_MAP_OPTIONS: "UPDATE_MAP_OPTIONS",
  UPDATE_FULL_MAP: "UPDATE_FULL_MAP",
  UPDATE_STATUS_FILTER_LIST: "UPDATE_STATUS_FILTER_LIST",

  // Point Detail
  GET_POINT_DETAIL_REQUEST: "GET_POINT_DETAIL_REQUEST",
  GET_POINT_DETAIL_SUCCESS: "GET_POINT_DETAIL_SUCCESS",
  GET_POINT_DETAIL_FAILURE: "GET_POINT_DETAIL_FAILURE",
  CLEAR_POINT_DETAIL_ERROR: "CLEAR_POINT_DETAIL_ERROR",
  UPDATE_SHOW_ALL: "UPDATE_SHOW_ALL",
  UPDATE_HIGHLIGHTED_EVENTS: "UPDATE_HIGHLIGHTED_EVENTS",
  UPDATE_POINT_DETAIL_DATE: "UPDATE_POINT_DETAIL_DATE",
  ADD_POINT_STATUS: "ADD_POINT_STATUS",
  REMOVE_POINT_STATUS: "REMOVE_POINT_STATUS",
  // pantovision
  GET_PANTOVISION_SUCCESS: "GET_PANTOVISION_SUCCESS",
  GET_PANTOVISION_REQUEST: "GET_PANTOVISION_REQUEST",
  GET_PANTOVISION_FAILURE: "GET_PANTOVISION_FAILURE",
  UPDATE_PANTOVISION_SHOW_ALL: "UPDATE_PANTOVISION_SHOW_ALL",
  UPDATE_PANTOVISION_DATE: "UPDATE_PANTOVISION_DATE",
  CLEAR_PANTOVISION_ERROR: "CLEAR_PANTOVISION_ERROR",
  ADD_PANTOGRAPHS_STATUS: "ADD_PANTOGRAPHS_STATUS",
  REMOVE_PANTOGRAPHS_STATUS: "REMOVE_PANTOGRAPHS_STATUS",

  // Device Settings
  UPDATE_DEVICE: "UPDATE_DEVICE",
  UPDATE_DEVICE_FILES: "UPDATE_DEVICE_FILES",
  DELETE_DEVICE_FILE: "DELETE_DEVICE_FILE",
  GET_DEVICES_REQUEST: "GET_DEVICES_REQUEST",
  GET_DEVICES_SUCCESS: "GET_DEVICES_SUCCESS",
  GET_DEVICES_FAILURE: "GET_DEVICES_FAILURE",
  UPDATE_SELECTED_DEVICE: "UPDATE_SELECTED_DEVICE",
  ADD_DIVICES_ACCESS: "ADD_DIVICES_ACCESS",
  REMOVE_DEVICES_ACCESS: "REMOVE_DEVICES_ACCESS",

  // Simulation
  GET_SIMULATION_PROJECTS_REQUEST: "GET_SIMULATION_PROJECTS_REQUEST",
  GET_SIMULATION_PROJECTS_SUCCESS: "GET_SIMULATION_PROJECTS_SUCCESS",
  GET_SIMULATION_PROJECTS_FAILURE: "GET_SIMULATION_PROJECTS_FAILURE",
  SET_SIMULATION_PROJECTS_FILTER: "SET_SIMULATION_PROJECTS_FILTER",
  SET_SIMULATION_SEARCH: "CHANGE_SIMULATION_SEARCH",
  DELETE_CATENARY: "DELETE_CATENARY",
  DUPLICATE_CATENARY: "DUPLICATE_CATENARY",

  //Supervision
  CHANGE_VIDEO_STATUS: "CHANGE_VIDEO_STATUS",
  UPDATE_SUPERVISION_HIGHLIGHT: "UPDATE_SUPERVISION_HIGHLIGHT",
  GET_LASER_SUCCESS: "GET_LASER_SUCCESS",

  // Settings
  ADD_ACCOUNT: "ADD_ACCOUNT",
  EDIT_ACCOUNT: "EDIT_ACCOUNT",
  DELETE_ACCOUNT: "DELETE_ACCOUNT",
  GET_GENERAL_SETTINGS_REQUEST: "GET_GENERAL_SETTINGS_REQUEST",
  GET_GENERAL_SETTINGS_SUCCESS: "GET_GENERAL_SETTINGS_SUCCESS",
  GET_GENERAL_SETTINGS_FAILURE: "GET_GENERAL_SETTINGS_FAILURE",

  //Pantographs
  GET_PANTOGRAPHS_EVENTS_REQUEST: "GET_PANTOGRAPHS_EVENTS_REQUEST",
  GET_PANTOGRAPHS_EVENTS_SUCCESS: "GET_PANTOGRAPHS_EVENTS_SUCCESS",
  GET_PANTOGRAPHS_EVENTS_FAILURE: ".GET_PANTOGRAPHS_EVENTS_FAILURE",
  GET_PANTOGRAPHS_FAILURE: "GET_PANTOGRAPHS_FAILURE",
  GET_PANTOGRAPHS_SUCCESS: "GET_PANTOGRAPHS_SUCCESS",
  GET_PANTOGRAPHS_REQUEST: "GET_PANTOGRAPHS_REQUEST",
  UPDATE_SELECTED_PANTOGRAPHS: "UPDATE_SELECTED_PANTOGRAPHS",
  UPDATE_HIGHLIGHTED_PANTOGRAPH: "UPDATE_HIGHLIGHTED_PANTOGRAPH",
  UPDATE_PANTOGRAPHS_FILTER_DATE: "UPDATE_PANTOGRAPHS_FILTER_DATE",
  UPDATE_PANTOGRAPHS_SELECTED_TAB: "UPDATE_PANTOGRAPHS_SELECTED_TAB",
  UPDATE_PANTOGRAPHS_FILTER_RANGE: "UPDATE_PANTOGRAPHS_FILTER_RANGE",
  UPDATE_SELECTED_PANTOGRAPHS_FILTER: "UPDATE_SELECTED_PANTOGRAPHS_FILTER",
  UPDATE_PANTOGRAPHS_MAP_OPTIONS: "UPDATE_PANTOGRAPHS_MAP_OPTIONS",
  UPDATE_PANTOGRAPHS_SORT_BY: "UPDATE_PANTOGRAPHS_SORT_BY",

  //Report
  GET_REPORT_REQUEST: "GET_REPORT_REQUEST",
  GET_REPORTS_ID_SUCCESS: "GET_REPORTS_ID_SUCCESS",
  GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
  GET_REPORT_FAILURE: "GET_REPORT_FAILURE",
  DELETE_REPORT: "DELETE_REPORT",
  ADD_REPORT: "ADD_REPORT",
  UPDATE_REPORT_TRIP_INFO: "UPDATE_REPORT_TRIP_INFO",
  UPDATE_REPORT_SELECTED_TRIP: "UPDATE_REPORT_SELECTED_TRIP",
  UPDATE_REPORT_SIGNALS: "UPDATE_REPORT_SIGNALS",
  UPDATE_REPORT_SIGNALS_IDS: "UPDATE_REPORT_SIGNALS_IDS",
  UPDATE_REPORT_DATE: "UPDATE_REPORT_DATE",
};

export const ADD_ACCOUNT_TO_CATENARY = "ADD_ACCOUNT_TO_CATENARY";
export const ADD_CATENARY_NOTIFICATION = "ADD_CATENARY_NOTIFICATION";
export const ADD_CATENARY_SECTION = "ADD_CATENARY_SECTION";
export const ADD_CATENARY_SPAN = "ADD_CATENARY_SPAN";
export const CHANGE_CATENARY_ACTIVE_SECTION = "CHANGE_CATENARY_ACTIVE_SECTION";
export const CHANGE_CATENARY_ACTIVE_SPAN = "CHANGE_CATENARY_ACTIVE_SPAN";
export const CHANGE_CATENARY_ACTIVE_TAB = "CHANGE_CATENARY_ACTIVE_TAB";
export const CHANGE_CATENARY_NAME = "CHANGE_CATENARY_NAME";
export const CHECK_MECHANICAL_PROPERTIES = "CHECK_MECHANICAL_PROPERTIES";
export const DELETE_CATENARY_SECTION = "DELETE_CATENARY_SECTION";
export const DELETE_CATENARY_SPAN = "DELETE_CATENARY_SPAN";
export const GET_CATENARY_FAILURE = "GET_CATENARY_FAILURE";
export const GET_CATENARY_REQUEST = "GET_CATENARY_REQUEST";
export const GET_CATENARY_SUCCESS = "GET_CATENARY_SUCCESS";
export const RESET_CATENARY_ERROR = "RESET_CATENARY_ERROR";
export const SET_CATENARY_ACCESS = "SET_CATENARY_ACCESS";
export const SET_CATENARY_DROPPER_ELASTICITY =
  "SET_CATENARY_DROPPER_ELASTICITY";
export const SET_CATENARY_MOVIE_MAKER = "SET_CATENARY_MOVIE_MAKER";
export const SET_CATENARY_SECTIONS = "SET_CATENARY_SECTIONS";
export const SET_CATENARY_TRAIN_VELOCITY = "SET_CATENARY_TRAIN_VELOCITY";
export const SET_OPEN_CATENARY_MODAL = "SET_OPEN_CATENARY_MODAL";
export const UPDATE_CATENARY_DYNAMIC_STATUS = "UPDATE_CATENARY_DYNAMIC_STATUS";
export const UPDATE_CATENARY_STATIC_STATUS = "UPDATE_CATENARY_STATIC_STATUS";
export const CHANGE_SELECTED_SECTION = "CHANGE_SELECTED_SECTION";
export const DISPLAYED_DRAGGABLE_NOTIC = "DISPLAYED_DRAGGABLE_NOTIC";
export const DISPLAYED_SECTIONS_DRAGGABLE_NOTIC =
  "DISPLAYED_SECTIONS_DRAGGABLE_NOTIC";

export const SET_IS_CURRENT_SPAN_DATA = "SET_IS_CURRENT_SPAN_DATA";
export const SET_CATENARY_PANTOGRAPH = "SET_CATENARY_PANTOGRAPH";
export const SET_CATENARY_PANTOGRAPHS = "SET_CATENARY_PANTOGRAPHS";
export const SET_ACTIVE_PANTOGRAPH = "SET_ACTIVE_PANTOGRAPH";
export const ADD_PANTOGRAPH_TO_CATENARY = "ADD_PANTOGRAPH_TO_CATENARY";

export const FAVORITE_POINT = "FAVORITE_POINT";
export const UNFAVORITE_POINT = "UNFAVORITE_POINT";
export const UPDATE_THEME = "UPDATE_THEME";
export const UPDATE_PANEL_MODE = "UPDATE_PANEL_MODE";
export const UPDATE_COMPANY_ID = "UPDATE_COMPANY_ID";

export const SET_CATENARY_CURRENT_TIME = "SET_CATENARY_CURRENT_TIME";
export const SET_CATENARY_ANALYSIS_SECTION = "SET_CATENARY_ANALYSIS_SECTION";
export const SET_LOADING_CATENARY = "SET_LOADING_CATENARY";

export const CANCEL_REQUEST = "CANCEL_REQUEST";
export const RESET_CATENARY_STATE = "RESET_CATENARY_STATE";

export const SET_LOADING_PROGRESS = "SET_LOADING_PROGRESS";
export const SET_CONTENT_LOADED = "SET_CONTENT_LOADED";

export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
